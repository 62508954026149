import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import KindergartenMenu from "../Component/kindergartenMenu";
import LazyLoad from 'react-lazyload';
const Awards = () => {
    return (
        <>
        <KindergartenMenu />
            <Breadcrumb title="Awards" mid="Kindergarten &nbsp; &nbsp;|" tabName="Awards" />
            <div className="innerPage">
                <div className="container">
                    <div class="row kidsactivities_sec_img">
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/awad6.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/awad6.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/awad7.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/awad7.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/awad8.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/awad8.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/awad1.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/awad1.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/awad2.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/awad2.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/awad3.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/awad3.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/awad4.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/awad4.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4"> <LazyLoad><a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/NCSR/public/Images/awad5.jpg"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/awad5.jpg" class="img-fluid midimg" alt="Nirmala Convent School Rajkot" /></a> </LazyLoad></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Awards;